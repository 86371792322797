import React from 'react'

const DownloadIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 12.5991L4.44986 8.0502L5.36014 7.12449L8.35714 10.1215V5.99921e-05L9.64286 0V10.1215L12.6386 7.12577L13.5501 8.0502L9 12.5991ZM2.07771 17.0001C1.48543 17.0001 0.991285 16.8021 0.595285 16.4061C0.199285 16.0101 0.000857143 15.5155 0 14.9223V11.8071H1.28571V14.9223C1.28571 15.1203 1.368 15.3021 1.53257 15.4675C1.69714 15.6329 1.87843 15.7152 2.07643 15.7143H15.9236C16.1207 15.7143 16.302 15.6321 16.4674 15.4675C16.6329 15.3029 16.7151 15.1212 16.7143 14.9223V11.8071H18V14.9223C18 15.5146 17.802 16.0088 17.406 16.4048C17.01 16.8008 16.5154 16.9992 15.9223 17.0001H2.07771Z"
        fill="currentColor"/>
    </svg>
  )
}

export default DownloadIcon
