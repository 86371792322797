const AddCircleIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="17" height="17" rx="8" fill="transparent" />
      <rect x="6" y="9" width="7" height="1" />
      <rect x="10" y="6" width="7" height="1" transform="rotate(90 10 6)" />
    </svg>
  )
}
export default AddCircleIcon
