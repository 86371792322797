import React from 'react'

const FilterSearchbarIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="3" width="3" height="1.8" fill="#2B2B2B"/>
      <rect y="9" width="12" height="1.8" fill="#2B2B2B"/>
      <rect y="15" width="3" height="1.8" fill="#2B2B2B"/>
      <rect x="3" y="1" width="6" height="6" rx="3" fill="transparent" stroke="#2B2B2B" stroke-width="1.5"/>
      <rect x="3" y="13" width="6" height="6" rx="3" fill="transparent" stroke="#2B2B2B" stroke-width="1.5"/>
      <rect x="12" y="7" width="6" height="6" rx="3" fill="transparent" stroke="#2B2B2B" stroke-width="1.5"/>
      <rect x="9" y="3" width="12" height="1.8" fill="#2B2B2B"/>
      <rect x="18" y="9" width="3" height="1.8" fill="#2B2B2B"/>
      <rect x="9" y="15" width="12" height="1.8" fill="#2B2B2B"/>
    </svg>

  )
}

export default FilterSearchbarIcon
