const BathroomIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 140 140">
      <g fill="currentColor">
        <path d="M86.473 49.116a1.68 1.68 0 001.667-1.68 1.667 1.667 0 10-3.333 0c0 .92.745 1.68 1.666 1.68M86.473 55.529c.918 0 1.667-.747 1.667-1.667a1.667 1.667 0 10-1.667 1.667M86.473 61.956c.918 0 1.667-.747 1.667-1.667a1.667 1.667 0 10-1.667 1.667M86.473 68.37a1.67 1.67 0 001.667-1.667 1.666 1.666 0 10-1.667 1.667M94.552 61.956a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333M94.552 68.37a1.667 1.667 0 100-3.335 1.667 1.667 0 000 3.335M94.552 49.103c.92 0 1.667-.748 1.667-1.667 0-.92-.746-1.654-1.667-1.654-.92 0-1.667.734-1.667 1.654 0 .92.748 1.667 1.667 1.667M94.552 55.529a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334M102.633 49.116c.92 0 1.666-.76 1.666-1.667a1.66 1.66 0 00-1.666-1.667c-.92 0-1.667.734-1.667 1.667 0 .906.747 1.667 1.667 1.667M102.633 68.37a1.667 1.667 0 10-.001-3.332 1.667 1.667 0 000 3.332M102.633 61.956c.92 0 1.666-.76 1.666-1.667a1.668 1.668 0 00-3.333 0c0 .906.747 1.667 1.667 1.667M102.633 55.529a1.668 1.668 0 10-.002-3.335 1.668 1.668 0 00.002 3.335" /><path d="M114.02 104.854c-4.235 4.439-9.862 6.689-16.726 6.689H42.706c-6.86 0-12.485-2.249-16.72-6.684-8.67-9.077-9.202-24.815-9.192-28.827h106.41c.015 4.009-.509 19.732-9.183 28.822zm-9.757 17.288h-.992l-3.802-8.69a27.709 27.709 0 004.794-.75zm-68.571 0h-.994v-9.706c1.531.454 3.144.76 4.83.933zm67.305-82.37H86.114c.33-5.047 3.232-7.706 8.44-7.706 5.21 0 8.113 2.659 8.443 7.706zm29.44 34.26h-7.481V31.553c0-8.655-7.042-15.695-15.697-15.695-8.47 0-15.155 6.185-15.655 14.244-6.062.362-9.522 4.2-9.522 10.67a1 1 0 001 1h18.947a1 1 0 001-1c0-6.436-3.42-10.27-9.425-10.667.495-6.938 6.307-12.247 13.655-12.247 7.552 0 13.697 6.144 13.697 13.695v42.48H7.563a1 1 0 000 2h7.226c-.039 4.286.466 20.481 9.745 30.203 2.346 2.458 5.075 4.295 8.164 5.516v11.39a1 1 0 001 1h2.647c.397 0 .757-.236.917-.6l4.394-10.044c.352.012.69.045 1.05.045h54.588l.03-.001 4.377 10c.16.364.519.6.916.6h2.646a1 1 0 001-1v-11.034c3.513-1.203 6.602-3.145 9.205-5.874 9.277-9.72 9.78-25.915 9.74-30.202h7.23a1 1 0 100-2" />
      </g>
    </svg>
  )
}

export default BathroomIcon
