import React from 'react'

const LocationIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13952 19.905C7.24989 19.9673 7.37426 20 7.50074 20C7.62722 20 7.75158 19.9673 7.86196 19.905C9.99081 18.7033 11.771 16.9617 13.0261 14.8529C14.3191 12.6758 15.0013 10.1858 15 7.64851C15 7.59722 14.9991 7.54543 14.9982 7.49396C14.9974 7.44312 14.9965 7.39259 14.9965 7.34316C14.9502 5.08143 14.1132 3.17826 12.5785 1.83554C11.224 0.651963 9.42083 0 7.50074 0C5.58065 0 3.77702 0.651963 2.42096 1.83554C0.887753 3.17677 0.0523087 5.08143 0.0029618 7.34316C0.000987923 7.44461 9.84521e-07 7.54639 9.84521e-07 7.64851C-0.0018934 12.7365 2.73016 17.4267 7.13952 19.905ZM3.3926 2.96293C4.47774 2.01407 5.93693 1.49191 7.50074 1.49191C9.06454 1.49191 10.5232 2.01407 11.6089 2.96293C12.8189 4.02019 13.4791 5.54591 13.5181 7.37499C13.5211 7.46547 13.5211 7.55694 13.5211 7.64842V7.64851C13.5223 9.91509 12.9131 12.1395 11.7584 14.0846C10.7086 15.8481 9.24699 17.3262 7.50074 18.3902C3.76346 16.1215 1.47891 12.0453 1.48041 7.64851C1.48041 7.60263 1.48115 7.55675 1.4819 7.511C1.48263 7.4655 1.48337 7.42012 1.48337 7.37499C1.52186 5.54591 2.18212 4.02019 3.3926 2.96293Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7.5C3 9.98528 5.01472 12 7.5 12C9.98419 11.9974 11.9974 9.98419 12 7.5C12 5.01472 9.98528 3 7.5 3C5.01472 3 3 5.01472 3 7.5ZM4.66484 6.32625C5.13979 5.17949 6.25878 4.43177 7.5 4.43177V4.43321C9.19329 4.43504 10.5658 5.80671 10.5687 7.5C10.5689 8.74122 9.82135 9.86032 8.67467 10.3355C7.52799 10.8106 6.20801 10.5482 5.33027 9.67055C4.45253 8.79294 4.18989 7.473 4.66484 6.32625Z" fill="currentColor"/>
    </svg>
  )
}

export default LocationIcon
