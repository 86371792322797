export const config = {
  environment: process.env.NEXT_PUBLIC_APP_ENV!,

  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  appURL: process.env.NEXT_PUBLIC_APP_BASE_URL,
  whitelabelURL: process.env.NEXT_PUBLIC_WHITELABEL_BASE_URL,
  graphQlURL: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL!,

  enableImageLoader: process.env.NEXT_PUBLIC_APP_ENV === 'production',

  travelAgentInquiryFormLink: process.env.NEXT_PUBLIC_TRAVEL_AGENT_INQUIRIES_LINK!,

  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,

  email: 'info@browseportfolio.com',
}
