const HouseSizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 213 199" fill="currentColor">
      <defs>
        <clipPath id="a">
          <path d="M0 0h213v199H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="M67 41h4v108h-4zM189 41h4v108h-4zM114 91h4v53h-4zM71 144h118v5H71zM142 91h4v53h-4zM118 91h24v5h-24z" />
        <path d="M130.735 0l2.12 3.392-82.256 51.41-2.12-3.392z" />
        <path d="M128.62 3.392L130.74 0l82.256 51.41-2.12 3.392zM63 178h133v5H63z" />
        <path d="M73.556 165.345l2.828 2.828-15.556 15.557L58 180.9z" />
        <path d="M58 180.828L60.828 178l15.557 15.556-2.829 2.828zM183 168.173l2.828-2.828 15.557 15.556-2.829 2.828z" />
        <path d="M198.556 178.001l2.828 2.828-15.556 15.557-2.828-2.829zM21.557 8.116v133h-5v-133z" />
        <path d="M31.384 128.115l2.828 2.828L18.656 146.5l-2.828-2.829z" />
        <path d="M21.557 143.672l-2.828 2.828-15.557-15.556 2.829-2.828zM34.213 18.672L31.385 21.5 15.828 5.944l2.829-2.828z" />
        <path d="M18.729 3.116l2.828 2.828L6.001 21.501l-2.828-2.829z" />
      </g>
    </svg>
  )
}
export default HouseSizeIcon
