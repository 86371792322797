const MapPinIcon = ({ className }: { className?: string }) => {
  return (
    <svg id="Iconly_Bulk_Home" data-name="Iconly/Bulk/Home" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 29 29" fill="currentColor" className={className}>
      <g id="Home" transform="translate(3.021 2.417)">
        <path id="Home-2" data-name="Home" d="M8.028,22.7V18.989a1.714,1.714,0,0,1,1.709-1.708H13.21a1.714,1.714,0,0,1,1.72,1.708h0v3.7a1.488,1.488,0,0,0,1.482,1.482h2.369a4.181,4.181,0,0,0,2.952-1.207,4.12,4.12,0,0,0,1.224-2.928V9.5a2.988,2.988,0,0,0-1.082-2.3L13.827.815A3.764,3.764,0,0,0,9.045.9L1.168,7.206A2.99,2.99,0,0,0,0,9.5V20.02a4.161,4.161,0,0,0,4.176,4.146H6.492a1.488,1.488,0,0,0,1.055-.427A1.466,1.466,0,0,0,7.985,22.7Z" transform="translate(0 0)" />
      </g>
    </svg>
  )
}
export default MapPinIcon
