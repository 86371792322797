const CalendarSearchBarIcon = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.84615 20H16.1538C18.278 20 20 18.3211 20 16.25V5.75C20 3.67893 18.278 2 16.1538 2H3.84615C1.72198 2 0 3.67893 0 5.75V16.25C0 18.3211 1.72198 20 3.84615 20ZM1.53846 5.75C1.53846 4.50736 2.57165 3.5 3.84615 3.5H16.1538C17.4284 3.5 18.4615 4.50736 18.4615 5.75V16.25C18.4615 17.4926 17.4284 18.5 16.1538 18.5H3.84615C2.57165 18.5 1.53846 17.4926 1.53846 16.25V5.75Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.83333 14H7.16667C7.6269 14 8 13.6269 8 13.1667V9.83333C8 9.3731 7.6269 9 7.16667 9H3.83333C3.3731 9 3 9.3731 3 9.83333V13.1667C3 13.6269 3.3731 14 3.83333 14ZM6.33333 12.3333H4.66667V10.6667H6.33333V12.3333Z" fill="currentColor" />
      <path d="M19.2308 7.5H0.769231C0.344396 7.5 0 7.16421 0 6.75C0 6.33579 0.344396 6 0.769231 6H19.2308C19.6556 6 20 6.33579 20 6.75C20 7.16421 19.6556 7.5 19.2308 7.5Z" fill="currentColor" />
      <path d="M6 5C5.44772 5 5 4.6269 5 4.16667V0.833333C5 0.373096 5.44772 0 6 0C6.55228 0 7 0.373096 7 0.833333V4.16667C7 4.6269 6.55228 5 6 5Z" fill="currentColor" />
      <path d="M14 5C13.4477 5 13 4.6269 13 4.16667V0.833333C13 0.373096 13.4477 0 14 0C14.5523 0 15 0.373096 15 0.833333V4.16667C15 4.6269 14.5523 5 14 5Z" fill="currentColor" />
    </svg>
  )
}
export default CalendarSearchBarIcon
