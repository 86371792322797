const BedroomIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 140 140">
      <g fill="currentColor">
        <path d="M88.846 122.393l-50.398-8.953V28.241l50.398-10.595zm.795-106.958L37.242 26.45a1.002 1.002 0 00-.794.979v85.849h-6.544a1 1 0 000 2h7.458l52.31 9.291a1.002 1.002 0 001.175-.984V16.414a1 1 0 00-1.206-.98" />
        <path d="M81.146 73.185a3.185 3.185 0 003.19-3.188 3.185 3.185 0 00-3.19-3.187 3.185 3.185 0 00-3.185 3.187 3.185 3.185 0 003.185 3.188M110.096 113.279h-6.541v-85.85a1 1 0 00-1-1h-7.766a1 1 0 000 2h6.766v84.85h-6.766a1 1 0 000 2h15.307a1 1 0 000-2" />
      </g>
    </svg>
  )
}

export default BedroomIcon
