import React from 'react'
import { default as NextImage } from 'next/image' // eslint-disable-line no-restricted-imports
import { config } from 'data/config'

const normaliseSrc = src => {
  let url = src.startsWith('/')
    ? src.slice(1)
    : src

  const httpRegex = new RegExp(/^https?:\/\/?/)
  url = httpRegex.test(url)
    ? url
    : `${config.whitelabelURL}/${url}`

  return url
}

const loader = config.enableImageLoader
  ? ({ src, width, height, quality }) => {
    const params = [
      `width=${width}`, // specify the width to resize to
      'format=auto',    // serve in avif or webp if possible
      'fit=scale-down', // prevent upscaling if the image is smaller than the requested size
    ]

    if (height) {
      params.push(`height=${height}`)
    }

    if (quality) {
      params.push(`quality=${quality}`)
    }

    const paramsString = params.join(',')
    return `${config.appURL}/cdn-cgi/image/${paramsString}/${normaliseSrc(src)}`
  }
  : ({ src }) => {
    return src
  }

const Image = (props) => {
  return (
    <NextImage
      loader={loader}
      unoptimized={config.environment !== 'production'}
      {...props}
    />
  )
}

export default Image
