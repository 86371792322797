import NextLink from 'next/link'
import { UrlObject } from 'url'

const Link = ({
  href,
  className,
  children,
  target,
  about,
}: {
  href: string | UrlObject,
  className?: string,
  children: React.ReactNode,
  target?: string,
  about?: string,
}) => {
  return (
    <NextLink href={href} about={about}>
      <a target={target} className={className}>
        {children}
      </a>
    </NextLink>
  )
}

export default Link
